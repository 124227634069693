/* tslint:disable */
/* eslint-disable */

export type ValueTypes = {
    ["ArticleGraph"]: AliasType<{
	canEdit?:true,
	content?:true,
	id?:true,
	isPrivate?:true,
	title?:true,
		__typename?: true
}>;
	["ArticleInputGraph"]: {
	id?:ValueTypes["Guid"],
	title:string,
	content:string,
	isPrivate:boolean
};
	["BigInt"]:unknown;
	["Byte"]:unknown;
	["DashboardCardGraph"]: AliasType<{
	canEdit?:true,
	chineseToDisplay?:true,
	chineseToDisplayId?:true,
	editUrl?:true,
	id?:true,
	learnerCount?:true,
	level?:true,
	name?:true,
	progress?:true,
	type?:true,
	viewUrl?:true,
	wordCount?:true,
		__typename?: true
}>;
	["DashboardCardType"]:DashboardCardType;
	/** The `Date` scalar type represents a year, month and day in accordance with the
[ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
["Date"]:unknown;
	/** The `DateTime` scalar type represents a date and time. `DateTime` expects
timestamps to be formatted in accordance with the
[ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
["DateTime"]:unknown;
	/** The `DateTimeOffset` scalar type represents a date, time and offset from UTC.
`DateTimeOffset` expects timestamps to be formatted in accordance with the
[ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
["DateTimeOffset"]:unknown;
	["Decimal"]:unknown;
	["EnglishTranslationGraph"]: AliasType<{
	english?:true,
	id?:true,
	importance?:true,
	notes?:true,
		__typename?: true
}>;
	["EnglishTranslationInputGraph"]: {
	id:ValueTypes["Guid"]
};
	["Guid"]:unknown;
	["LearnerContentMutation"]: AliasType<{
addTranslation?: [{	/** The id of the Chinese word to add a translation to */
	id:ValueTypes["Guid"],	translation:string},ValueTypes["WordGraph"]],
getQuizAndUpdateLessonProgress?: [{	id:ValueTypes["Guid"]},ValueTypes["QuizGraph"]],
savePageProgress?: [{	id:ValueTypes["Guid"],	index:number},true],
updateQuizProgress?: [{	id:ValueTypes["Guid"],	lastAnswerWasCorrect:boolean,	quizType:ValueTypes["QuizType"]},ValueTypes["WordGraph"]],
upsertArticle?: [{	article:ValueTypes["ArticleInputGraph"]},ValueTypes["PageGraph"]],
upsertLesson?: [{	lesson:ValueTypes["LessonCreationGraphInput"]},ValueTypes["LessonGraph"]],
		__typename?: true
}>;
	["LearnerContentQuery"]: AliasType<{
article?: [{	id:ValueTypes["Guid"]},ValueTypes["ArticleGraph"]],
	articles?:ValueTypes["ArticleGraph"],
getQuiz?: [{	id:ValueTypes["Guid"]},ValueTypes["QuizGraph"]],
lessonById?: [{	id:ValueTypes["Guid"]},ValueTypes["LessonGraph"]],
lessons?: [{	name?:string},ValueTypes["QuizGraph"]],
page?: [{	id:ValueTypes["Guid"],	startIndex?:number},ValueTypes["PageGraph"]],
recommendations?: [{	lessonsIndex:number,	articlesIndex:number},ValueTypes["DashboardCardGraph"]],
	settings?:ValueTypes["SettingsGraph"],
	user?:ValueTypes["UserGraph"],
wordById?: [{	id:ValueTypes["Guid"]},ValueTypes["WordGraph"]],
words?: [{	searchTerm?:string,	exact?:boolean},ValueTypes["WordGraph"]],
		__typename?: true
}>;
	["LessonCreationGraphInput"]: {
	id?:ValueTypes["Guid"],
	name:string,
	description:string,
	isPrivate:boolean,
	lessonWords?:ValueTypes["WordGraphInput"][]
};
	["LessonGraph"]: AliasType<{
	description?:true,
	id?:true,
	isPrivate?:true,
	lessonSentences?:ValueTypes["LessonSentenceGraph"],
	lessonWords?:ValueTypes["LessonWordGraph"],
	level?:true,
	name?:true,
	progress?:true,
		__typename?: true
}>;
	["LessonSentenceGraph"]: AliasType<{
	english?:true,
	id?:true,
	index?:true,
	notes?:true,
	pinyin?:true,
	progress?:true,
	simplifiedChinese?:true,
	words?:ValueTypes["WordGraph"],
		__typename?: true
}>;
	["LessonWordGraph"]: AliasType<{
	chineseWordId?:true,
	englishTranslations?:ValueTypes["EnglishTranslationGraph"],
	frequency?:true,
	hSKLevel?:true,
	importance?:true,
	index?:true,
	notes?:true,
	pinyin?:true,
	preferredTranslations?:ValueTypes["EnglishTranslationGraph"],
	simplifiedChinese?:true,
	strokeCount?:true,
	traditionalChinese?:true,
		__typename?: true
}>;
	["Long"]:unknown;
	/** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
["Milliseconds"]:unknown;
	["PageGraph"]: AliasType<{
	canEdit?:true,
	chineseWords?:ValueTypes["WordGraph"],
	id?:true,
	isPrivate?:true,
	lastIndex?:true,
	nextIndex?:true,
	pageSize?:true,
	progress?:true,
	segments?:ValueTypes["SegmentGraph"],
	title?:true,
	totalLength?:true,
		__typename?: true
}>;
	["QuizGraph"]: AliasType<{
	description?:true,
	lessonId?:true,
	level?:true,
	name?:true,
	progress?:true,
	quizWords?:ValueTypes["QuizWordGraph"],
		__typename?: true
}>;
	["QuizType"]:QuizType;
	["QuizWordGraph"]: AliasType<{
	chineseWordId?:true,
	englishTranslations?:ValueTypes["EnglishTranslationGraph"],
	frequency?:true,
	hSKLevel?:true,
	importance?:true,
	index?:true,
	notes?:true,
	pinyin?:true,
	preferredTranslations?:ValueTypes["EnglishTranslationGraph"],
	progress?:true,
	quizType?:true,
	simplifiedChinese?:true,
	strokeCount?:true,
	traditionalChinese?:true,
		__typename?: true
}>;
	["SByte"]:unknown;
	/** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
["Seconds"]:unknown;
	["SegmentGraph"]: AliasType<{
	type?:true,
	word?:true,
		__typename?: true
}>;
	["SettingsGraph"]: AliasType<{
	maxArticleLength?:true,
		__typename?: true
}>;
	["Short"]:unknown;
	["UInt"]:unknown;
	["ULong"]:unknown;
	["Uri"]:unknown;
	["UserGraph"]: AliasType<{
	dashboardCards?:ValueTypes["DashboardCardGraph"],
	lessonsCompleted?:true,
	lessonsInProgress?:true,
	wordsInProgress?:true,
	wordsLearned?:true,
		__typename?: true
}>;
	["UShort"]:unknown;
	["WordGraph"]: AliasType<{
	englishTranslations?:ValueTypes["EnglishTranslationGraph"],
	frequency?:true,
	hSKLevel?:true,
	id?:true,
	importance?:true,
	notes?:true,
	pinyin?:true,
	simplifiedChinese?:true,
	strokeCount?:true,
	traditionalChinese?:true,
		__typename?: true
}>;
	["WordGraphInput"]: {
	chineseWordId:ValueTypes["Guid"],
	index:number,
	preferredTranslations:ValueTypes["EnglishTranslationInputGraph"][]
};
	["WordType"]:WordType
  }

export type PartialObjects = {
    ["ArticleGraph"]: {
		__typename?: "ArticleGraph";
			canEdit?:boolean,
			content?:string,
			id?:string,
			isPrivate?:boolean,
			title?:string
	},
	["ArticleInputGraph"]: {
	id?:PartialObjects["Guid"],
	title:string,
	content:string,
	isPrivate:boolean
},
	["BigInt"]:any,
	["Byte"]:any,
	["DashboardCardGraph"]: {
		__typename?: "DashboardCardGraph";
			canEdit?:boolean,
			chineseToDisplay?:string,
			chineseToDisplayId?:string,
			editUrl?:string,
			id?:string,
			learnerCount?:PartialObjects["UInt"],
			level?:number,
			name?:string,
			progress?:PartialObjects["Decimal"],
			type?:PartialObjects["DashboardCardType"],
			viewUrl?:string,
			wordCount?:PartialObjects["UInt"]
	},
	["DashboardCardType"]:DashboardCardType,
	/** The `Date` scalar type represents a year, month and day in accordance with the
[ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
["Date"]:any,
	/** The `DateTime` scalar type represents a date and time. `DateTime` expects
timestamps to be formatted in accordance with the
[ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
["DateTime"]:any,
	/** The `DateTimeOffset` scalar type represents a date, time and offset from UTC.
`DateTimeOffset` expects timestamps to be formatted in accordance with the
[ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
["DateTimeOffset"]:any,
	["Decimal"]:any,
	["EnglishTranslationGraph"]: {
		__typename?: "EnglishTranslationGraph";
			english?:string,
			id?:string,
			importance?:number,
			notes?:string
	},
	["EnglishTranslationInputGraph"]: {
	id:PartialObjects["Guid"]
},
	["Guid"]:any,
	["LearnerContentMutation"]: {
		__typename?: "LearnerContentMutation";
			addTranslation?:PartialObjects["WordGraph"],
			getQuizAndUpdateLessonProgress?:PartialObjects["QuizGraph"],
			savePageProgress?:number,
			updateQuizProgress?:PartialObjects["WordGraph"],
			upsertArticle?:PartialObjects["PageGraph"],
			upsertLesson?:PartialObjects["LessonGraph"]
	},
	["LearnerContentQuery"]: {
		__typename?: "LearnerContentQuery";
			article?:PartialObjects["ArticleGraph"],
			articles?:PartialObjects["ArticleGraph"][],
			getQuiz?:PartialObjects["QuizGraph"],
			lessonById?:PartialObjects["LessonGraph"],
			lessons?:PartialObjects["QuizGraph"][],
			page?:PartialObjects["PageGraph"],
			recommendations?:PartialObjects["DashboardCardGraph"][],
			settings?:PartialObjects["SettingsGraph"],
			user?:PartialObjects["UserGraph"],
			wordById?:PartialObjects["WordGraph"],
			words?:PartialObjects["WordGraph"][]
	},
	["LessonCreationGraphInput"]: {
	id?:PartialObjects["Guid"],
	name:string,
	description:string,
	isPrivate:boolean,
	lessonWords?:PartialObjects["WordGraphInput"][]
},
	["LessonGraph"]: {
		__typename?: "LessonGraph";
			description?:string,
			id?:string,
			isPrivate?:boolean,
			lessonSentences?:PartialObjects["LessonSentenceGraph"][],
			lessonWords?:PartialObjects["LessonWordGraph"][],
			level?:number,
			name?:string,
			progress?:PartialObjects["Decimal"]
	},
	["LessonSentenceGraph"]: {
		__typename?: "LessonSentenceGraph";
			english?:string,
			id?:string,
			index?:PartialObjects["UInt"],
			notes?:string,
			pinyin?:string,
			progress?:PartialObjects["Decimal"],
			simplifiedChinese?:string,
			words?:PartialObjects["WordGraph"][]
	},
	["LessonWordGraph"]: {
		__typename?: "LessonWordGraph";
			chineseWordId?:string,
			englishTranslations?:PartialObjects["EnglishTranslationGraph"][],
			frequency?:number,
			hSKLevel?:number,
			importance?:number,
			index?:PartialObjects["UInt"],
			notes?:string,
			pinyin?:string,
			preferredTranslations?:PartialObjects["EnglishTranslationGraph"][],
			simplifiedChinese?:string,
			strokeCount?:number,
			traditionalChinese?:string
	},
	["Long"]:any,
	/** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
["Milliseconds"]:any,
	["PageGraph"]: {
		__typename?: "PageGraph";
			canEdit?:boolean,
			chineseWords?:PartialObjects["WordGraph"][],
			id?:string,
			isPrivate?:boolean,
			lastIndex?:number,
			nextIndex?:number,
			pageSize?:PartialObjects["UShort"],
			progress?:number,
			segments?:PartialObjects["SegmentGraph"][],
			title?:string,
			totalLength?:number
	},
	["QuizGraph"]: {
		__typename?: "QuizGraph";
			description?:string,
			lessonId?:string,
			level?:number,
			name?:string,
			progress?:PartialObjects["Decimal"],
			quizWords?:PartialObjects["QuizWordGraph"][]
	},
	["QuizType"]:QuizType,
	["QuizWordGraph"]: {
		__typename?: "QuizWordGraph";
			chineseWordId?:string,
			englishTranslations?:PartialObjects["EnglishTranslationGraph"][],
			frequency?:number,
			hSKLevel?:number,
			importance?:number,
			index?:PartialObjects["UInt"],
			notes?:string,
			pinyin?:string,
			preferredTranslations?:PartialObjects["EnglishTranslationGraph"][],
			progress?:PartialObjects["Decimal"],
			quizType?:PartialObjects["QuizType"],
			simplifiedChinese?:string,
			strokeCount?:number,
			traditionalChinese?:string
	},
	["SByte"]:any,
	/** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
["Seconds"]:any,
	["SegmentGraph"]: {
		__typename?: "SegmentGraph";
			type?:PartialObjects["WordType"],
			word?:string
	},
	["SettingsGraph"]: {
		__typename?: "SettingsGraph";
			maxArticleLength?:number
	},
	["Short"]:any,
	["UInt"]:any,
	["ULong"]:any,
	["Uri"]:any,
	["UserGraph"]: {
		__typename?: "UserGraph";
			dashboardCards?:PartialObjects["DashboardCardGraph"][],
			lessonsCompleted?:PartialObjects["UInt"],
			lessonsInProgress?:PartialObjects["UInt"],
			wordsInProgress?:PartialObjects["UInt"],
			wordsLearned?:PartialObjects["UInt"]
	},
	["UShort"]:any,
	["WordGraph"]: {
		__typename?: "WordGraph";
			englishTranslations?:PartialObjects["EnglishTranslationGraph"][],
			frequency?:number,
			hSKLevel?:number,
			id?:string,
			importance?:number,
			notes?:string,
			pinyin?:string,
			simplifiedChinese?:string,
			strokeCount?:number,
			traditionalChinese?:string
	},
	["WordGraphInput"]: {
	chineseWordId:PartialObjects["Guid"],
	index:number,
	preferredTranslations:PartialObjects["EnglishTranslationInputGraph"][]
},
	["WordType"]:WordType
  }

export type ArticleGraph = {
	__typename?: "ArticleGraph",
	canEdit:boolean,
	content:string,
	id:string,
	isPrivate:boolean,
	title:string
}

export type ArticleInputGraph = {
		id?:Guid,
	title:string,
	content:string,
	isPrivate:boolean
}

export type BigInt = any

export type Byte = any

export type DashboardCardGraph = {
	__typename?: "DashboardCardGraph",
	canEdit:boolean,
	chineseToDisplay:string,
	chineseToDisplayId:string,
	editUrl:string,
	id:string,
	learnerCount:UInt,
	level:number,
	name:string,
	progress:Decimal,
	type:DashboardCardType,
	viewUrl:string,
	wordCount:UInt
}

export enum DashboardCardType {
	READING = "READING",
	VOCABULARY = "VOCABULARY"
}

/** The `Date` scalar type represents a year, month and day in accordance with the
[ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
export type Date = any

/** The `DateTime` scalar type represents a date and time. `DateTime` expects
timestamps to be formatted in accordance with the
[ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
export type DateTime = any

/** The `DateTimeOffset` scalar type represents a date, time and offset from UTC.
`DateTimeOffset` expects timestamps to be formatted in accordance with the
[ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
export type DateTimeOffset = any

export type Decimal = any

export type EnglishTranslationGraph = {
	__typename?: "EnglishTranslationGraph",
	english:string,
	id:string,
	importance:number,
	notes?:string
}

export type EnglishTranslationInputGraph = {
		id:Guid
}

export type Guid = any

export type LearnerContentMutation = {
	__typename?: "LearnerContentMutation",
	addTranslation:WordGraph,
	getQuizAndUpdateLessonProgress:QuizGraph,
	savePageProgress:number,
	updateQuizProgress?:WordGraph,
	upsertArticle:PageGraph,
	upsertLesson:LessonGraph
}

export type LearnerContentQuery = {
	__typename?: "LearnerContentQuery",
	article:ArticleGraph,
	articles:ArticleGraph[],
	getQuiz:QuizGraph,
	lessonById:LessonGraph,
	lessons:QuizGraph[],
	page:PageGraph,
	recommendations:DashboardCardGraph[],
	settings:SettingsGraph,
	user:UserGraph,
	wordById:WordGraph,
	words:WordGraph[]
}

export type LessonCreationGraphInput = {
		id?:Guid,
	name:string,
	description:string,
	isPrivate:boolean,
	lessonWords?:WordGraphInput[]
}

export type LessonGraph = {
	__typename?: "LessonGraph",
	description:string,
	id:string,
	isPrivate:boolean,
	lessonSentences:LessonSentenceGraph[],
	lessonWords:LessonWordGraph[],
	level:number,
	name:string,
	progress:Decimal
}

export type LessonSentenceGraph = {
	__typename?: "LessonSentenceGraph",
	english:string,
	id:string,
	index:UInt,
	notes?:string,
	pinyin:string,
	progress?:Decimal,
	simplifiedChinese:string,
	words:WordGraph[]
}

export type LessonWordGraph = {
	__typename?: "LessonWordGraph",
	chineseWordId:string,
	englishTranslations:EnglishTranslationGraph[],
	frequency:number,
	hSKLevel?:number,
	importance:number,
	index:UInt,
	notes?:string,
	pinyin:string,
	preferredTranslations:EnglishTranslationGraph[],
	simplifiedChinese:string,
	strokeCount:number,
	traditionalChinese:string
}

export type Long = any

/** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
export type Milliseconds = any

export type PageGraph = {
	__typename?: "PageGraph",
	canEdit:boolean,
	chineseWords:WordGraph[],
	id:string,
	isPrivate:boolean,
	lastIndex:number,
	nextIndex:number,
	pageSize:UShort,
	progress:number,
	segments:SegmentGraph[],
	title:string,
	totalLength:number
}

export type QuizGraph = {
	__typename?: "QuizGraph",
	description:string,
	lessonId:string,
	level:number,
	name:string,
	progress:Decimal,
	quizWords:QuizWordGraph[]
}

export enum QuizType {
	HANDWRITING = "HANDWRITING",
	PINYIN = "PINYIN",
	COMPREHENSION = "COMPREHENSION",
	PRESENTATION = "PRESENTATION"
}

export type QuizWordGraph = {
	__typename?: "QuizWordGraph",
	chineseWordId:string,
	englishTranslations:EnglishTranslationGraph[],
	frequency:number,
	hSKLevel?:number,
	importance:number,
	index:UInt,
	notes?:string,
	pinyin:string,
	preferredTranslations:EnglishTranslationGraph[],
	progress:Decimal,
	quizType:QuizType,
	simplifiedChinese:string,
	strokeCount:number,
	traditionalChinese:string
}

export type SByte = any

/** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
export type Seconds = any

export type SegmentGraph = {
	__typename?: "SegmentGraph",
	type:WordType,
	word:string
}

export type SettingsGraph = {
	__typename?: "SettingsGraph",
	maxArticleLength:number
}

export type Short = any

export type UInt = any

export type ULong = any

export type Uri = any

export type UserGraph = {
	__typename?: "UserGraph",
	dashboardCards:DashboardCardGraph[],
	lessonsCompleted:UInt,
	lessonsInProgress:UInt,
	wordsInProgress:UInt,
	wordsLearned:UInt
}

export type UShort = any

export type WordGraph = {
	__typename?: "WordGraph",
	englishTranslations:EnglishTranslationGraph[],
	frequency:number,
	hSKLevel?:number,
	id:string,
	importance:number,
	notes?:string,
	pinyin:string,
	simplifiedChinese:string,
	strokeCount:number,
	traditionalChinese:string
}

export type WordGraphInput = {
		chineseWordId:Guid,
	index:number,
	preferredTranslations:EnglishTranslationInputGraph[]
}

export enum WordType {
	ADJECTIVAL_MORPHEME = "ADJECTIVAL_MORPHEME",
	ADJECTIVE = "ADJECTIVE",
	ADJECTIVE_USED_AS_ADVERBIAL = "ADJECTIVE_USED_AS_ADVERBIAL",
	ADJECTIVAL_NOUN = "ADJECTIVAL_NOUN",
	DISTINGUISHING_WORDS = "DISTINGUISHING_WORDS",
	CONJUNCTION = "CONJUNCTION",
	ADVERBIAL_MORPHEME = "ADVERBIAL_MORPHEME",
	POSITIONAL_ADVERB = "POSITIONAL_ADVERB",
	ADVERB = "ADVERB",
	EXCLAMATION = "EXCLAMATION",
	POSITIONAL_WORD = "POSITIONAL_WORD",
	MORPHEME = "MORPHEME",
	FRONT_COMPONENT = "FRONT_COMPONENT",
	FOUR_LETTER_IDIOM = "FOUR_LETTER_IDIOM",
	ABBREVIATION = "ABBREVIATION",
	FINAL_COMPONENT = "FINAL_COMPONENT",
	IDIOM = "IDIOM",
	NUMERAL = "NUMERAL",
	NUMERAL_MORPHEME = "NUMERAL_MORPHEME",
	NUMERAL_MEASURE_WORD = "NUMERAL_MEASURE_WORD",
	NOMINAL_MORPHEME = "NOMINAL_MORPHEME",
	NOUN = "NOUN",
	NOUN_MORPHEME = "NOUN_MORPHEME",
	NAME_OF_PERSON = "NAME_OF_PERSON",
	MISC_NOUN_1 = "MISC_NOUN_1",
	MISC_NOUN_2 = "MISC_NOUN_2",
	NAME_OF_PLACE = "NAME_OF_PLACE",
	NAME_OF_ORGANIZATION = "NAME_OF_ORGANIZATION",
	OTHER_PROPER_NOUN = "OTHER_PROPER_NOUN",
	ONOMATOPOEIA = "ONOMATOPOEIA",
	PREPOSITION = "PREPOSITION",
	MEASURE_WORD = "MEASURE_WORD",
	PRONOUN = "PRONOUN",
	PERSONAL_PRONOUN = "PERSONAL_PRONOUN",
	DEMONSTRATIVE_PRONOUN = "DEMONSTRATIVE_PRONOUN",
	PRONOMINAL_MORPHEME = "PRONOMINAL_MORPHEME",
	INTERROGATIVE_PRONOUN = "INTERROGATIVE_PRONOUN",
	LOCATION_WORD = "LOCATION_WORD",
	TIME_MORPHEME = "TIME_MORPHEME",
	TIME = "TIME",
	PARTICLE_1 = "PARTICLE_1",
	PARTICLE_2 = "PARTICLE_2",
	PARTICLE_MORPHEME = "PARTICLE_MORPHEME",
	PARTICLE_4 = "PARTICLE_4",
	PARTICLE_5 = "PARTICLE_5",
	PARTICLE_6 = "PARTICLE_6",
	PARTICLE_7 = "PARTICLE_7",
	VERB = "VERB",
	ADVERB_VERB = "ADVERB_VERB",
	VERB_MORPHEME = "VERB_MORPHEME",
	VERB_2 = "VERB_2",
	VERB_3 = "VERB_3",
	NOUN_VERB = "NOUN_VERB",
	PUNCTUATION = "PUNCTUATION",
	NON_MORPHEME = "NON_MORPHEME",
	MODAL = "MODAL",
	MODAL_2 = "MODAL_2",
	STATE_WORD = "STATE_WORD",
	STATE_WORD_MORPHEME = "STATE_WORD_MORPHEME",
	ENGLISH = "ENGLISH"
}

export const AllTypesProps: Record<string,any> = {
	ArticleInputGraph:{
		id:{
			type:"Guid",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		isPrivate:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	BigInt: "String",
	Byte: "String",
	DashboardCardType: "enum",
	Date: "String",
	DateTime: "String",
	DateTimeOffset: "String",
	Decimal: "String",
	EnglishTranslationInputGraph:{
		id:{
			type:"Guid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Guid: "String",
	LearnerContentMutation:{
		addTranslation:{
			id:{
				type:"Guid",
				array:false,
				arrayRequired:false,
				required:true
			},
			translation:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getQuizAndUpdateLessonProgress:{
			id:{
				type:"Guid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		savePageProgress:{
			id:{
				type:"Guid",
				array:false,
				arrayRequired:false,
				required:true
			},
			index:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateQuizProgress:{
			id:{
				type:"Guid",
				array:false,
				arrayRequired:false,
				required:true
			},
			lastAnswerWasCorrect:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:true
			},
			quizType:{
				type:"QuizType",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		upsertArticle:{
			article:{
				type:"ArticleInputGraph",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		upsertLesson:{
			lesson:{
				type:"LessonCreationGraphInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	LearnerContentQuery:{
		article:{
			id:{
				type:"Guid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getQuiz:{
			id:{
				type:"Guid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		lessonById:{
			id:{
				type:"Guid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		lessons:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		page:{
			id:{
				type:"Guid",
				array:false,
				arrayRequired:false,
				required:true
			},
			startIndex:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		recommendations:{
			lessonsIndex:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			},
			articlesIndex:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		wordById:{
			id:{
				type:"Guid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		words:{
			searchTerm:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			exact:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	LessonCreationGraphInput:{
		id:{
			type:"Guid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		isPrivate:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:true
		},
		lessonWords:{
			type:"WordGraphInput",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Long: "String",
	Milliseconds: "String",
	QuizType: "enum",
	SByte: "String",
	Seconds: "String",
	Short: "String",
	UInt: "String",
	ULong: "String",
	Uri: "String",
	UShort: "String",
	WordGraphInput:{
		chineseWordId:{
			type:"Guid",
			array:false,
			arrayRequired:false,
			required:true
		},
		index:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		preferredTranslations:{
			type:"EnglishTranslationInputGraph",
			array:true,
			arrayRequired:true,
			required:true
		}
	},
	WordType: "enum"
}

export const ReturnTypes: Record<string,any> = {
	ArticleGraph:{
		canEdit:"Boolean",
		content:"String",
		id:"ID",
		isPrivate:"Boolean",
		title:"String"
	},
	DashboardCardGraph:{
		canEdit:"Boolean",
		chineseToDisplay:"String",
		chineseToDisplayId:"ID",
		editUrl:"String",
		id:"ID",
		learnerCount:"UInt",
		level:"Int",
		name:"String",
		progress:"Decimal",
		type:"DashboardCardType",
		viewUrl:"String",
		wordCount:"UInt"
	},
	EnglishTranslationGraph:{
		english:"String",
		id:"ID",
		importance:"Float",
		notes:"String"
	},
	LearnerContentMutation:{
		addTranslation:"WordGraph",
		getQuizAndUpdateLessonProgress:"QuizGraph",
		savePageProgress:"Int",
		updateQuizProgress:"WordGraph",
		upsertArticle:"PageGraph",
		upsertLesson:"LessonGraph"
	},
	LearnerContentQuery:{
		article:"ArticleGraph",
		articles:"ArticleGraph",
		getQuiz:"QuizGraph",
		lessonById:"LessonGraph",
		lessons:"QuizGraph",
		page:"PageGraph",
		recommendations:"DashboardCardGraph",
		settings:"SettingsGraph",
		user:"UserGraph",
		wordById:"WordGraph",
		words:"WordGraph"
	},
	LessonGraph:{
		description:"String",
		id:"ID",
		isPrivate:"Boolean",
		lessonSentences:"LessonSentenceGraph",
		lessonWords:"LessonWordGraph",
		level:"Int",
		name:"String",
		progress:"Decimal"
	},
	LessonSentenceGraph:{
		english:"String",
		id:"ID",
		index:"UInt",
		notes:"String",
		pinyin:"String",
		progress:"Decimal",
		simplifiedChinese:"String",
		words:"WordGraph"
	},
	LessonWordGraph:{
		chineseWordId:"ID",
		englishTranslations:"EnglishTranslationGraph",
		frequency:"Int",
		hSKLevel:"Int",
		importance:"Float",
		index:"UInt",
		notes:"String",
		pinyin:"String",
		preferredTranslations:"EnglishTranslationGraph",
		simplifiedChinese:"String",
		strokeCount:"Int",
		traditionalChinese:"String"
	},
	PageGraph:{
		canEdit:"Boolean",
		chineseWords:"WordGraph",
		id:"ID",
		isPrivate:"Boolean",
		lastIndex:"Int",
		nextIndex:"Int",
		pageSize:"UShort",
		progress:"Int",
		segments:"SegmentGraph",
		title:"String",
		totalLength:"Int"
	},
	QuizGraph:{
		description:"String",
		lessonId:"ID",
		level:"Int",
		name:"String",
		progress:"Decimal",
		quizWords:"QuizWordGraph"
	},
	QuizWordGraph:{
		chineseWordId:"ID",
		englishTranslations:"EnglishTranslationGraph",
		frequency:"Int",
		hSKLevel:"Int",
		importance:"Float",
		index:"UInt",
		notes:"String",
		pinyin:"String",
		preferredTranslations:"EnglishTranslationGraph",
		progress:"Decimal",
		quizType:"QuizType",
		simplifiedChinese:"String",
		strokeCount:"Int",
		traditionalChinese:"String"
	},
	SegmentGraph:{
		type:"WordType",
		word:"String"
	},
	SettingsGraph:{
		maxArticleLength:"Int"
	},
	UserGraph:{
		dashboardCards:"DashboardCardGraph",
		lessonsCompleted:"UInt",
		lessonsInProgress:"UInt",
		wordsInProgress:"UInt",
		wordsLearned:"UInt"
	},
	WordGraph:{
		englishTranslations:"EnglishTranslationGraph",
		frequency:"Int",
		hSKLevel:"Int",
		id:"ID",
		importance:"Float",
		notes:"String",
		pinyin:"String",
		simplifiedChinese:"String",
		strokeCount:"Int",
		traditionalChinese:"String"
	}
}

export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }



export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};

type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};

type NotUndefined<T> = T extends undefined ? never : T;

export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;

interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}

export type ValuesOf<T> = T[keyof T];

export type MapResolve<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  ValuesOf<{
    [k in (keyof SRC['__resolve'] & keyof DST)]: ({
      [rk in (keyof SRC['__resolve'][k] & keyof DST[k])]: LastMapTypeSRCResolver<SRC['__resolve'][k][rk], DST[k][rk]>
    } & {
      __typename: SRC['__resolve'][k]['__typename']
    })
  }>
  :
  never;

export type MapInterface<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  (MapResolve<SRC, DST> extends never ? {} : MapResolve<SRC, DST>) & {
  [k in (keyof SRC['__interface'] & keyof DST)]: LastMapTypeSRCResolver<SRC['__interface'][k], DST[k]>
} : never;

export type ValueToUnion<T> = T extends {
  __typename: infer R;
}
  ? {
      [P in keyof Omit<T, '__typename'>]: T[P] & {
        __typename: R;
      };
    }
  : T;

export type ObjectToUnion<T> = {
  [P in keyof T]: T[P];
}[keyof T];

type Anify<T> = { [P in keyof T]?: any };


type LastMapTypeSRCResolver<SRC, DST> = SRC extends undefined
  ? undefined
  : SRC extends Array<infer AR>
  ? LastMapTypeSRCResolver<AR, DST>[]
  : SRC extends { __interface: any; __resolve: any }
  ? MapInterface<SRC, DST>
  : SRC extends { __union: any; __resolve: infer RESOLVE }
  ? ObjectToUnion<MapType<RESOLVE, ValueToUnion<DST>>>
  : DST extends boolean
  ? SRC
  : MapType<SRC, DST>;

export type MapType<SRC extends Anify<DST>, DST> = DST extends boolean
  ? SRC
  : DST extends {
      __alias: any;
    }
  ? {
      [A in keyof DST["__alias"]]: Required<SRC> extends Anify<
        DST["__alias"][A]
      >
        ? MapType<Required<SRC>, DST["__alias"][A]>
        : never;
    } &
      {
        [Key in keyof Omit<DST, "__alias">]: DST[Key] extends [
          any,
          infer PAYLOAD
        ]
          ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
          : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
      }
  : {
      [Key in keyof DST]: DST[Key] extends [any, infer PAYLOAD]
        ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
        : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
    };

type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, variables?: Record<string, any>) => Promise<MapType<T, Z>>;

type CastToGraphQL<V, T> = (
  resultOfYourQuery: any
) => <Z extends V>(o: Z | V) => MapType<T, Z>;

type fetchOptions = ArgsType<typeof fetch>;

export type SelectionFunction<V> = <T>(t: T | V) => T;
type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .map((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).map((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  variables?: Record<string, any>,
) => fn(queryConstruct(t, tName)(o), variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  


export const Thunder = (fn: FetchFunction) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(fn)('query', 'LearnerContentQuery')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["LearnerContentQuery"],LearnerContentQuery>,
mutation: ((o: any, variables) =>
    fullChainConstruct(fn)('mutation', 'LearnerContentMutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["LearnerContentMutation"],LearnerContentMutation>
});

export const Chain = (...options: fetchOptions) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('query', 'LearnerContentQuery')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["LearnerContentQuery"],LearnerContentQuery>,
mutation: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('mutation', 'LearnerContentMutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["LearnerContentMutation"],LearnerContentMutation>
});
export const Zeus = {
  query: (o:ValueTypes["LearnerContentQuery"]) => queryConstruct('query', 'LearnerContentQuery')(o),
mutation: (o:ValueTypes["LearnerContentMutation"]) => queryConstruct('mutation', 'LearnerContentMutation')(o)
};
export const Cast = {
  query: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["LearnerContentQuery"],
  LearnerContentQuery
>,
mutation: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["LearnerContentMutation"],
  LearnerContentMutation
>
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["LearnerContentQuery"]>(),
mutation: ZeusSelect<ValueTypes["LearnerContentMutation"]>()
};
  

export const Gql = Chain(`${window.location.protocol}//${window.location.host}/api/graphql`)